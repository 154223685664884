import * as React from 'react';
import {styled} from '@mui/material/styles';
import Button, {ButtonProps} from '@mui/material/Button';
import MaterialLink from "@mui/material/Link";
import {Link, useLocation} from "react-router-dom";

const ColorButton = styled(Button)<ButtonProps & { colorhover: string }>(({ theme, color, colorhover }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  color,
  '&:hover': {
    color: colorhover,
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
  },
}));

const CustomLink = (props: { title: string, onClick: any, href?: string }) => {
  const { title, onClick, href } = props;

  const { pathname } = useLocation();
  const color = pathname === href ? '#ff6217' : '#999999';
  const colorHover = pathname === href ? '#ff6217' : '#ffffff';

  return (
    <ColorButton variant="text" onClick={onClick} sx={{ color }} colorhover={colorHover}>
      <MaterialLink to={href ?? '#'} sx={{ textDecoration: 'none', color: 'inherit' }} component={Link}>
        {title}
      </MaterialLink>
    </ColorButton>
  );
}

export default CustomLink;