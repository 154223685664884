import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Toolbar } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';


import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

interface ISideBar {
    mobileOpen: boolean;
    handleDrawerToggle: any
}

interface IMenu {
    title: string;
    icon: JSX.Element,
    href: string
}

const menus: IMenu[] = [
    {
        title: 'Home',
        icon: <HomeIcon style={{ color: "fe6217" }} />,
        href: '/'
    },
    {
        title: 'Contas',
        icon: <ManageAccountsIcon style={{ color: "fe6217" }} />,
        href: '/admin/clientes'
    }
]

const options: IMenu[] = [
    {
        title: 'Sair',
        icon: <LogoutIcon style={{ color: "fe6217" }} />,
        href: '/admin/logout'
    }
]

export default function SideBar({ mobileOpen, handleDrawerToggle }: ISideBar) {


    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {menus.map(({ title, icon, href }, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton component={Link} to={href}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {options.map(({ title, icon, href }, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton component={Link} to={href}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (<Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="admin dashboard"
    >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
        >
            {drawer}
        </Drawer>
    </Box>)
}
