import { Box, Grid, IconButton, Typography } from "@mui/material"
import KeyIcon from '@mui/icons-material/Key';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import ApiClientApiService from "@/services/ApiClientApiService";

export interface IApiCard {
    id: number,
    code: string,
    createdAt: string,
    setApis?: Function
}

const ApiCard = ({id, code, createdAt, setApis}: IApiCard) => {

    const apiClientApiService = new ApiClientApiService();

    const deleteApi = async () => {
        try {
            if(setApis) {
                setApis((apis:any) => apis.filter((api: any) => api.id !== id))
            }
            await apiClientApiService.delete(id);
            
        } catch (error) {
            
        }
    }
    
    return <Box sx={{
        bgcolor: "primary.light",
        borderRadius: 4,
        p: 2
    }}>
        <Grid 
            container   
            justifyContent="center"
            alignItems="center" 
        >
            <Grid item xs={10} >
                <Box sx={{display: "flex", gap: "1rem", alignItems: "center"}}>
                    <Grid3x3Icon color="secondary"/>
                    <Typography variant="overline">
                        {id}
                    </Typography>
                </Box>
                <Box mt={2} sx={{display: "flex", gap: "1rem", wordBreak: "break-word"}}>
                    <KeyIcon color="secondary"/>
                    <Typography variant="body1">
                        {code}
                    </Typography>
                </Box>
                <Box mt={2} sx={{display: "flex", gap: "1rem"}}>
                    <AccessTimeIcon color="secondary"/>
                    <Typography variant="subtitle2" color="text">
                        {createdAt}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{ display: "flex", justifyContent: "center"}}>
                <IconButton onClick={deleteApi}>
                    <DeleteIcon color="secondary" style={{fontSize: "2.5rem"}}/>
                </IconButton>
                </Box>
            </Grid>

        </Grid>


    </Box>
}

export default ApiCard