
import AuthApiService from "./AuthApiService";

class ApiMetricApiService extends AuthApiService {
    async get(userId: number, startDate: Date | null, endDate: Date | null): Promise<any> {
        const result = await this.makeHttpRequest({
            method: "GET",
            url: `/ApiMetric/consolidated`,
            params: {
                UserId: userId,
                DateStart: startDate,
                DateEnd: endDate,
            }
        })

        return result.data;
    }
}

export default ApiMetricApiService;