
import AuthApiService from "./AuthApiService";

class UserApiService extends AuthApiService {
    async get(page: number, limit: number) {
        const results = await this.makeHttpRequest({
            method: "GET",
            url: `/User/`,
            params: {
                Page: page,
                PageSize: limit
            }
        })

        return results
    }

    async findById(id: number) {
        const result = await this.makeHttpRequest({
            method: "GET",
            url: `/User/${id}`
        })

        return result;
    }

    async create(name: string, email: string, responsible: string) {
        const result = await this.makeHttpRequest({
            method: "POST",
            url: `/Auth/register`,
            data: {
                name, email, responsible
            }
        })

        return result;
    }

    async update(userId: number, fieldsToUpdate: object) {
        const result = await this.makeHttpRequest({
            method: "PATCH",
            url: `/User/${userId}`,
            data: fieldsToUpdate
        })

        return result;
    }
}

export default UserApiService;