import * as React from 'react';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import AppBar from './components/AppBar';
import SideBar from './components/SideBar';
import { Outlet } from 'react-router-dom';

const drawerWidth = 240;


export default function Layout() {

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
            <SideBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}
