import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ApiTab from "./components/ApiTab";
import ConsumptionTab from "./components/ConsumptionTab";
import UserApiService from "@/services/UserApiService";
import PersonIcon from '@mui/icons-material/Person';


interface ITab {
    id: string,
    title: string,
    component: Function
}


const tabs: ITab[] = [
    {
        id: '1',
        title: "API Keys",
        component: (props: any) => <ApiTab {...props} />
    },
    {
        id: '2',
        title: "Consumos",
        component: (props: any) => <ConsumptionTab {...props} />
    }
]


const ClientsDetails = () => {

    const { id } = useParams();
    const [client, setClient] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [value, setValue] = useState<string>('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    useEffect(() => { 
        const getClient = async () => {
            const userApiService = new UserApiService();
            try {
                setLoading(true);
                const user = await userApiService.findById(Number.parseInt(id as string));
                console.log(user);
                setClient(user)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        }
        getClient();
    }, [id])

    if (loading) return <LinearProgress />

    return <Box>
        <Box mb={2}>
            <Box mt={2} sx={{display: "flex", gap: ".5rem", wordBreak: "break-word", alignItems: "center"}}>
                <PersonIcon color="secondary"/>
                <Typography variant="h5">
                    {client?.name}
                </Typography>
            </Box>
        </Box>
        <Divider />
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="Tables" textColor="secondary"
                    indicatorColor="secondary">
                    {tabs.map(({ id, title }) => <Tab key={id} label={title} value={id} />
                    )}
                </TabList>
            </Box>
            {tabs.map(({ id: tableId, component }) => <TabPanel key={tableId} value={tableId}>{component({ clientId: id })}</TabPanel>)}
        </TabContext>
    </Box>
}

export default ClientsDetails;