import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useState } from "react";
import { Box, Button, Grid, LinearProgress } from '@mui/material';
import RouteCard from './components/RouteCard';
import ApiMetricApiService from '@/services/ApiMetricApiService';

interface IConsumptionTab {
    clientId: string;
}


const ConsumptionTab = ({ clientId }: IConsumptionTab) => {

    const apiMetricApiService = new ApiMetricApiService();

    const [startDateTime, setStartDateTime] = useState<Date | null>(null);
    const [finalDateTime, setFinalDateTime] = useState<Date | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [metrics, setMetrics] = useState<any>(null);

    const cleanDates = () => {
        setStartDateTime(null);
        setFinalDateTime(null);
    }

    const getConsumption = async () => {
        try {
            setLoading(true);
            const _metrics = await apiMetricApiService.get(Number.parseInt(clientId), startDateTime, finalDateTime)
            setMetrics(_metrics);
        
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DateTimePicker
                            renderInput={(props) => <TextField fullWidth {...props} />}
                            label="Data/hora inicial"
                            value={startDateTime}
                            onChange={(newValue) => {
                                setStartDateTime(newValue);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DateTimePicker
                            renderInput={(props) => <TextField fullWidth {...props} />}
                            label="Data/hora final"
                            value={finalDateTime}
                            onChange={(newValue) => {
                                setFinalDateTime(newValue);
                            }}
                        />
                    </Grid>
                </Grid>
                <Box mt={2} sx={{ display: 'flex', justifyContent: 'end', gap: '.5rem' }}>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={cleanDates}
                        style={{ color: 'white' }}
                    >Limpar</Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={getConsumption}
                        style={{ color: 'white' }}
                    >Pesquisar</Button>
                </Box>
            </Box>
            <Box mt={2}>
                {
                    loading 
                        ? 
                            <LinearProgress />
                        :
                            <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {metrics?.map((metric:any, i:number) => {
                                return <Grid key={i} item xs={12} sm={4} md={3} >
                                    <RouteCard path={metric.requestUrl} count={metric.count}/>
                                </Grid>
                            })}
                            </Grid>
                }

            </Box>
        </Box>
    </LocalizationProvider >;
}

export default ConsumptionTab