import { useDispatch } from "react-redux";
import { useEffect } from "react";
import AuthApiService from "@/services/AuthApiService";
import { requestClear } from "@/store/slices/auth";



const Logout = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const authApiService = new AuthApiService()
        try {
            authApiService.logout();
            dispatch(requestClear());
        } catch (error) {

        }
    }, [dispatch])

    return <></>
}

export default Logout;