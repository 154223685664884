enum SectionCa {
    DESCRIPTION = "DESCRIÇÃO",
    APPROVED_FOR = "APROVADO PARA",
    PRODUCT_REFERENCE = "REFERÊNCIA DO PRODUTO",
    PRODUCER = "FABRICANTE",
    CA_MARKING = "MARCAÇÃO DE CA",
    TECHNICAL_STANDARDS = "NORMA(S) TÉCNICA(S)",
    OBSERVATION = "OBSERVAÇÃO"
}

export default SectionCa