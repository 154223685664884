import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, InputAdornment, LinearProgress } from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import UserApiService from '@/services/UserApiService';

interface IClientsNewModal {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface IFormClient {
    name: string,
    email: string;
    responsible: string;
}

const ClientsNewModal = ({ open, setOpen }: IClientsNewModal) => {

    const userApiService = new UserApiService();
    const { register, handleSubmit } = useForm<IFormClient>();
    const [loading, setLoading] = React.useState<boolean>(false);


    const onSubmit: SubmitHandler<IFormClient> = async ({ name, email, responsible }) => {
        try {
            setLoading(true);
            const newUser = await userApiService.create(name, email, responsible);
            console.log(newUser)
            setOpen(false);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    return (<Box>
        <Dialog open={open} onClose={() => setOpen(false)}>
            {loading && <LinearProgress />}
            <DialogTitle>Adicionar Cliente</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Informe os dados do cliente a ser adicionado
                </DialogContentText>
                <Box>
                    <form id="userForm" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} mt={2}>
                            <Grid xs={12} item>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Nome"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PermIdentityOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("name")}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Email"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("email")}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Responsavel"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("responsible")}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(false)}
                    style={{ color: 'white' }}
                >
                    Cancelar
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    style={{ color: 'white' }}
                    type="submit"
                    form="userForm"
                    disabled={loading}
                >Adicionar</Button>
            </DialogActions>
        </Dialog>
    </Box>);
}

export default ClientsNewModal