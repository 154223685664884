import { Box, Card, CardContent, Typography } from "@mui/material"
import RouteIcon from '@mui/icons-material/Route';

interface IRouteCard {
    path: string,
    count: number,
}

const RouteCard = ({path, count}: IRouteCard) => {

    return <Card sx={{ minHeight: '10rem' }}>
        <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5rem', bgcolor: 'secondary.main' }}>
            <RouteIcon htmlColor="white" sx={{ fontSize: '2rem' }} />
        </Box>
        <CardContent>
            <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '.1rem' }}>
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                    {path}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {count}
                </Typography>
            </Box>
        </CardContent>
    </Card>

}

export default RouteCard