import React from 'react';
import './index.css';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const Title = (props: { title: string }) => {
  const { title } = props;
  return (
    <Typography variant={'h5'} fontWeight={600}>{title}</Typography>
  )
};

const Body = (props: { children: any }) => {
  const { children } = props;
  return <Box px={6} py={1} display={'flex'} flexDirection={'column'}>{children}</Box>
};

const Privacy = () => {
  return (
    <Box py={2}>
      <Container>
        <Title title={'Política de Privacidade'} />

        <Body>
          <section>
            <p>A Mob Soluções digitais (“<strong>Mob</strong>”) deseja manter uma relação de confiança e transparência com todos aqueles com quem se relaciona. Nós agradecemos a confiança que você demonstra em nós quando compartilha seus Dados Pessoais conosco, e levamos a segurança das suas informações a sério.</p>
            <p>Para honrar esse compromisso, elaboramos este Aviso de Privacidade (ou simplesmente “Aviso”) que contém informações sobre como tratamos os seus dados pessoais: quais dados nós tratamos, por qual(is) motivo(s), e com quem os compartilhamos. Além disso, você encontrará nesse Aviso quais são os seus direitos relativos a essas informações e como exercê-los junto à <strong>Mob</strong>.</p>
            <p>É importante que, periodicamente, você releia estas informações, uma vez que este Aviso de Privacidade poderá sofrer alterações.</p>
            <p>Se você é um colaborador, as informações sobre como tratamos os seus dados pessoais se encontram disponíveis em nosso Aviso Interno de Privacidade.</p>
          </section>

          <section>
            <ol className='first-level'>
              <li id='o-que-voce-precisa-saber-antes-de-ler-esse-aviso'>
                <h1>O QUE VOCÊ PRECISA SABER ANTES DE LER ESSE AVISO?</h1>
                <p><span className='highlight'>Dados Pessoais</span> são todas as informações que permitem a sua identificação de forma direta ou que tenham o potencial de o tornar identificável. Como por exemplo, seu nome, CPF, e-mail, profissão, dentre outras.</p>
                <p><span className='highlight'>Encarregado</span> é a pessoa do nosso time responsável por cuidar da sua privacidade aqui na <strong>Mob</strong>. Se você tiver qualquer dúvida sobre como os seus dados são tratados por nós, é ele quem vai te ajudar.</p>
                <p><span className='highlight'>Tratamento</span> de Dados Pessoais significa qualquer operação, como a coleta, utilização, acesso, reprodução, compartilhamento, armazenamento, eliminação, realizada com Dados Pessoais.</p>
                <p>Toda vez que houver menção aos termos “<strong>Mob</strong>”, “nós” ou “nossos”, estamos nos referindo à <strong>Mob</strong>; de igual modo, toda vez que houver menção aos termos “você, “seu” ou “sua”, estamos nos referindo a você, Titular.</p>
              </li>

              <li id='quem-somos-nos'>
                <h1>Quem somos nós?</h1>
                <p>Para os fins da Lei Geral de Proteção de Dados Pessoais (“LGPD”), a <strong>Mob</strong> é a Controladora dos Dados Pessoais para as atividades e dados constantes no presente Aviso. Isso significa que a <strong>Mob</strong> determina quais dados são coletados, como os dados serão utilizados e para quais finalidades.</p>
              </li>

              <li id='quais-dados-pessoais-nos-tratamos-e-para-qual-finalidade'>
                <h1>QUAIS DADOS PESSOAIS NÓS TRATAMOS E PARA QUAL FINALIDADE?</h1>

                <p>Abaixo, apresentamos os principais Dados Pessoais que tratamos a seu respeito e os motivos.</p>

                <ol>
                  <li id='aquisicao-de-talentos'>
                    <h2>Aquisição de Talentos</h2>
                    <p>Nós, além de divulgarmos as nossas vagas em sites especializados, também recebemos currículos de indicações de nossos colaboradores, além dos currículos eventualmente deixados presencialmente em nossa sede. Nessas situações, nós iremos tratar seus Dados Pessoais para verificarmos as suas competências, currículo e atendimento aos requisitos específicos da vaga para a qual você se aplicou. Além disso, também iremos tratar seus Dados Pessoais para lhe enviar a carta oferta, nos casos nos quais desejarmos que você se torne um colaborador da <strong>Mob</strong>.</p>
                    <p>Para essas atividades, os seguintes Dados Pessoais podem ser tratados: Nome Completo, RG, CPF, Nacionalidade, Endereço, CEP, Cidade, E-mail, Telefone, Telefone Celular, Formação, Histórico Escolar/Acadêmico, Idiomas, Qualificações e Certificações, Estado Civil, Data de Nascimento, Idade, Cargo, Empresa/Entidade/Organização, Expectativa Salarial, Remuneração, Data de Contratação, Endereço Profissional, Jornada de Trabalho e Foto.</p>
                  </li>

                  <li id='relacoes-comerciais-clientes'>
                    <h2>Relações Comerciais – Clientes</h2>
                    <p>Nós tratamos seus Dados Pessoais, enquanto cliente, para que seja possível viabilizarmos a relação comercial originada pelo contrato firmado com você, sua empresa, ou a empresa que você representa.</p>
                    <p>Para isso, podemos coletar e tratar os seguintes dados : Assinatura, Nome Completo, RG, CPF, Carteira de Motorista, Endereço, CEP, Cidade, Estado, País, Endereço Anterior, E-mail, Telefone, Telefone Celular, Usuário em Aplicativo de Mensagens, Histórico Escolar/Acadêmico, Qualificações e Certificações, Nome dos Pais/Responsáveis, Data de Nascimento, Histórico de Crédito, Dados Bancários (Banco, Agência e Número da conta), Dados de Cartão de Crédito, Declaração de IRPF, Extrato Bancário, Remuneração, Histórico de Pagamentos, Score de Crédito, Idade, E-mail Profissional, Empresa/Entidade/Organização, Endereço Profissional, Identificação Profissional, Profissão, Hábitos de Consumo, Histórico de Reclamações. Esses dados são utilizados para as seguintes finalidades:</p>
                    <ol>
                      <li>Dar andamento em devoluções/trocas e averiguar reclamações de produtos e serviços;</li>
                      <li>Criar um cadastro em nossos sistemas com suas informações, facilitando e agilizando nosso relacionamento;</li>
                      <li>Efetuar os reembolsos ou estornos, por pagamento antecipado e compra não realizada, por devolução de valor referente material devolvido, ou por problema na venda efetuada;</li>
                      <li>Identificar e entregar as mercadorias adquiridas, mantendo você informado do status da entrega;</li>
                      <li>Realizar a análise de crédito, para garantir o recebimento das vendas a prazo com objetivo de proteção ao crédito;</li>
                      <li>Manter atualizados seus dados cadastrais em nossos sistemas;</li>
                      <li>Executar o contrato que você firmou conosco;</li>
                      <li>Manter o contato com você, prestando suporte e oferecendo nossos serviços conforme seu histórico de uso;</li>
                      <li>Realizar a cobrança de eventuais valores em aberto (vencidos) e não pagos; e</li>
                      <li>Verificar a existência e regularidade da empresa-cliente, para prosseguir com a venda dos produtos desejados.</li>
                    </ol>
                  </li>

                  <li id='relacoes-comerciais-fornecedores-prestadores-de-servico-e-parceiros'>
                    <h2>Relações Comerciais – Fornecedores, Prestadores de Serviços e Parceiros</h2>
                    <p>Nós podemos tratar seus Dados Pessoais para que seja possível viabilizarmos a relação comercial originada pelo contrato firmado com você, sua empresa, ou a empresa que você representa.</p>
                    <p>Para isso, podemos coletar os seguintes dados : Assinatura, CNPJ, Nome Completo, RG, CPF, Carteira de Motorista, Nacionalidade, Filiação, Data de Nascimento, Idade, Endereço, Estado, País, E-mail, Telefone, Telefone Celular, Usuário em Aplicativo de Mensagens, Cargo, Empresa/Entidade/Organização, Endereço Profissional, E-mail profissional, Dados de Treinamentos, Despesas Profissionais, Dados Bancários (Banco, Agência e Número da Conta), Extrato Bancário, Histórico de Crédito, Dados de Veículo, Histórico de Multas de Trânsito, e Perfil em Rede Social. Esses dados são utilizados para as seguintes finalidades:</p>
                    <ol>
                      <li>Fazer uma avaliação minuciosa de nossos parceiros e fornecedores;</li>
                      <li>Firmar contratos e executá-los em sua integralidade, bem como outros adendos contratuais, como NDAs, termos de cessão, termos aditivos e termos de encerramento;</li>
                      <li>Prospectar, renovar ou sub-rogar contratos de parceiros;</li>
                      <li>Cadastrar parceiros em nossos sistemas de gestão e seus principais pontos de contato;</li>
                      <li>Efetuar o pagamento pelos serviços tomados e pelos produtos adquiridos;</li>
                      <li>Realizar um acompanhamento do serviço contratado/prestado durante o contrato vigente, inclusive mediante elaboração de atas de reunião para acompanhamento posterior e monitoramento de desempenho;</li>
                      <li>Gerir os contratos assinados vigentes;</li>
                      <li>Manter um canal de contato ativo com os fornecedores, prestadores de serviços e parceiros, inclusive para fins de convite para eventos e campanhas de incentivo da <strong>Mob</strong>; e</li>
                      <li>Permitir auditorias internas.</li>
                    </ol>
                  </li>

                  <li id='obrigacoes-legais-ou-regulatorias'>
                    <h2>Obrigações Legais ou Regulatórias</h2>
                    <p>Em algumas situações, somos obrigados a tratar alguns Dados Pessoais seus para assegurar o cumprimento com a legislação, como, por exemplo, para:</p>
                    <ol>
                      <li>Assegurar a rastreabilidade do produto médico, utilizado nos seus atendimentos, perante a agência reguladora: Nome Completo, Carteira de Motorista, RG, CNPJ, CPF, Nacionalidade, E-mail, Telefone Celular, Usuário em Aplicativo de Mensagens, E-mail Profissional e Dado de Saúde do Produto a ser utilizado;</li>
                      <li>Emitir as Notas Fiscais/Notas Fiscais Eletrônicas: Nome completo, Razão Social, CPF, CNPJ, endereço e contato telefônico.</li>
                    </ol>
                  </li>

                  <li id='cadastro-academia-volk'>
                    <h2>Cadastro na Academia Volk</h2>
                    <p>Quando você realizar o cadastro na plataforma <a href='http://academia.volkdobrasil.com.br/cadastro' target='_noblank'>http://academia.volkdobrasil.com.br/cadastro</a>, para ter acesso aos cursos e treinamentos da Volk do Brasil, nós iremos tratar alguns de seus Dados Pessoais, como Nome, E-mail, CPF, Data de Nascimento, Telefone, Empresa, Cargo, Segmento, Estado, Cidade.</p>
                    <p>Esses dados também serão utilizados para o envio de comunicação, contato comercial, retorno de dúvidas e sugestões, retorno ao fornecedor (que ofertou serviço ou produto) e retorno, quando você tiver enviado seu currículo para trabalhar conosco.</p>
                  </li>

                  <li id='procedimentos-internos'>
                    <h2>Procedimentos Internos</h2>
                    <p>Para realizarmos nossas atividades regulares, como atividades financeiras e de contabilidade, marketing, prospecção de vendas e similares, precisamos tratar alguns Dados Pessoais seus, como: Nome Completo, Assinatura, CNPJ, RG, CPF, Número Interno de Registro, Nacionalidade, Endereço, CEP, Cidade, Estado, País, E-mail, Telefone, Telefone Celular, Data de Nascimento, Idade, Cargo, Empresa/Entidade/Organização, E-mail Profissional, Endereço Profissional, Função, Perfil em Rede Social, Dados do Veículo, Formação, Qualificações e Certificações, Experiência Profissional, Histórico Escolar/Acadêmico, Hábitos de Consumo, Tendências de Compra, Características de Navegação, Dados de Cookies, Histórico de Navegação, Histórico de Reclamações, Preferências de Entretenimento, Geolocalização, Histórico de Geolocalização, , Histórico de Crédito, Score de Crédito, Dados Bancários (Banco, Agência e Número da Conta), Dados do Cartão de Crédito, Histórico de Pagamentos e Fotos.</p>
                    <p>Esses dados são utilizados para:</p>
                    <ol>
                      <li>Construir e manter a base histórica de compras;</li>
                      <li>Manter registros de contas e outras transações comerciais e de pagamentos;</li>
                      <li>Analisar as categorias de compras para avaliar a composição das nossas atividades de compras;</li>
                      <li>Elaborar relatórios e análises internas, conforme necessário;</li>
                      <li>Gerar dados analíticos e estatísticos para monitorar e melhorar a performance de nosso site;</li>
                      <li>Garantir a segurança da operação de nosso site e sistemas internos;</li>
                      <li>Responder dúvidas e questionamentos da Diretoria;</li>
                      <li>Gerar leads e oportunidades de prospecção;</li>
                      <li>Enviar mailings com informações sobre nossos eventos e ações, e realizar as inscrições nos mesmos e;</li>
                      <li>Ajuizar ou recepcionar, cadastrar e gerir os processos judiciais;</li>
                      <li>Responder a consultas jurídicas internas das áreas da <strong>Mob</strong>.</li>
                    </ol>
                  </li>

                  <li id='exercicio-regular-de-direitos'>
                    <h2>Exercício regular de direitos</h2>
                    <p>Podemos também tratar alguns de seus dados pessoais quando isto se fizer necessário para resguardarmos os nossos direitos.</p>
                  </li>
                </ol>
              </li>

              <li id='com-quem-compartilhamos-os-seus-dados-pessoais'>
                <h1>COM QUEM COMPARTILHAMOS OS SEUS DADOS PESSOAIS?</h1>
                <p>Nós podemos compartilhar os seus Dados Pessoais quando necessário para atingir a finalidade para a qual os dados foram coletados ou quando existir uma obrigação para realizar o compartilhamento. Essa transferência pode ocorrer entre empresas do mesmo grupo econômico, com consultorias, escritórios jurídicos externos, empresas fornecedoras e de suporte aos sistemas da <strong>Mob</strong>, órgãos públicos (como Receita Federal do Brasil), Poder Judiciário, parceiros de negócio, empresas de transporte, fornecedores, instituições de pagamento e instituições financeiras.</p>
                <p>Para resguardar a sua privacidade, nos utilizamos de instrumentos contratuais para assegurar que os terceiros que recebam os seus Dados Pessoais em nosso nome confiram a eles a mesma proteção que nós.</p>
                <p>Eventualmente, por sermos uma organização global, é possível que haja transferência dos seus Dados Pessoais para fora do Brasil, para que as empresas do nosso grupo em outros países nos auxiliem a conduzir nossos negócios, ou para fornecedores e parceiros comerciais localizados no exterior.</p>
                <p>Nestes casos, nos certificaremos de que essa transferência ocorra apenas para os países que possuem um grau de segurança similar ao previsto pela legislação brasileira, ou quando a Autoridade Nacional de Proteção de Dados Pessoais assim autorizar.</p>
                <p>Você pode ter mais informações sobre os terceiros com quem compartilhamos os seus dados por meio de requisição específica, conforme detalhado no item 6. abaixo.</p>
              </li>

              <li id='quais-sao-os-seus-direitos'>
                <h1>QUAIS SÃO OS SEUS DIREITOS?</h1>
                <p>Você, enquanto Titular de Dados Pessoais, possui os seguintes direitos:</p>
                <ul>
                  <li>Saber se realizamos algum Tratamento com seus Dados Pessoais e quais dados são tratados;</li>
                  <li>Corrigir ou solicitar a correção de dados incompletos, inexatos ou desatualizados;</li>
                  <li>Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários ou excessivos em relação a finalidade para a qual foram coletados;</li>
                  <li>Solicitar a portabilidade dos dados a outro fornecedor de produtos ou serviços similares;</li>
                  <li>Obter informações sobre as entidades públicas ou privadas com as quais compartilhamos os seus dados;</li>
                  <li>Quando a atividade de Tratamento necessitar do seu consentimento, você pode se negar a consentir. Nesse caso, lhe informaremos sobre as consequências da não realização de tal atividade;</li>
                  <li>Quando a atividade de Tratamento estiver baseada do seu consentimento, você pode revogá-lo a qualquer momento.</li>
                </ul>
                <p>Para exercer os seus direitos, entre em contato com o nosso Encarregado, Ronaldo Ricioni, através do e-mail encarregado@<strong>Mob</strong>.com.br.</p>
              </li>

              <li id='cookies'>
                <h1>COOKIES</h1>
                  <p>Cookie é um pequeno arquivo adicionado ao seu dispositivo ou computador para fornecer uma experiência personalizada de acesso. Os cookies ajudam a analisar o tráfico de internet e permitem saber quando você visitou um site específico. Mas fique tranquilo, pois um cookie não dá acesso a seu computador ou revela informações além daquelas que você escolhe compartilhar.</p>
                  <p>A <strong>Mob</strong> utiliza de cookies para diferentes finalidades, como gerar estatísticas de acesso, possibilitar que você navegue sem complicação e de forma ágil, e para lembrar as suas preferências de idioma. Abaixo listamos as categorias de cookies que utilizamos em nossas aplicações e suas respectivas finalidades:</p>
                  <table>
                    <thead>
                      <tr>
                        <th>Tipos de Cookies</th>
                        <th>O que eles fazem?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Necessários</th>
                        <td>São cookies essenciais para viabilizar o adequado funcionamento do nosso website, assim como para permitir que você faça uso de todas as funcionalidades disponíveis.</td>
                      </tr>
                      <tr>
                        <th>Desempenho</th>
                        <td>São cookies que nos ajudam a entender como os visitantes interagem com o nosso website, fornecendo informações sobre as áreas visitadas, o tempo de visita ao site e quaisquer problemas eventualmente encontrados.</td>
                      </tr>
                      <tr>
                        <th>Funcionais</th>
                        <td>São cookies que permitem que o nosso website se lembre das suas escolhas anteriores, como, por exemplo idioma de navegação. São responsáveis por proporcionar uma experiência personalizada.</td>
                      </tr>
                      <tr>
                        <th>Marketing</th>
                        <td>São cookies utilizados para fornecer mais conteúdo relevante e específico para o seu interesse. Podem, ainda, ser utilizados para apresentar publicidade com um maior direcionamento ou limitar o número que esta é veiculada. Também, permitem a medição da eficácia de uma campanha lançada.</td>
                      </tr>
                    </tbody>
                  </table>

                  <p>De modo geral, os cookies que utilizamos coletam as seguintes informações: qual navegador você utiliza, informações sobre o seu endereço IP, juntamente com a data, hora e duração da sua visita, as páginas visualizadas e os links nos quais você clica.</p>
                  <p> pode desabilitar os cookies nas configurações do seu navegador. Mas lembre-se que os cookies necessários são essenciais para o normal funcionamento do nosso website, de modo que a oposição à utilização deles poderá implicar na inutilização de sua experiência ou na suspensão de seu acesso.</p>
                  <div id="ot-sdk-cookie-policy"></div>
                </li>

              <li id='por-quanto-tempo-tratamos-os-seus-dados-pessoais'>
                <h1>POR QUANTO TEMPO TRATAMOS OS SEUS DADOS PESSOAIS?</h1>
                <p>Reteremos seus dados pessoais pelo tempo que for necessário para cumprir a finalidade para a qual foram coletados, a menos que um período de retenção mais longo seja necessário para cumprir obrigações legais, resguardar nossos direitos ou cumprir acordos judiciais/extrajudiciais.</p>
                <p>Para determinar o período de retenção de seus dados pessoais, nos baseamos nos seguintes critérios:</p>
                <ol>
                  <li>Se temos uma obrigação legal, contratual ou outra de reter dados pessoais, ou se são necessários para fins de investigação ou litígio;</li>
                  <li>Se são necessários para manter registros comerciais e financeiros precisos.</li>
                </ol>
              </li>

              <li id='como-mantemos-os-seus-dados-pessoais-seguros'>
                <h1>COMO MANTEMOS OS SEUS DADOS PESSOAIS SEGUROS?</h1>
                <p>Nós adotamos políticas e procedimentos rigorosos que determinam como os dados pessoais devem ser tratados na <strong>Mob</strong>. Tais normas têm como objetivo garantir o tratamento adequado e em conformidade com as legislações aplicáveis de dados pessoais.</p>
                <p>Adotamos, também, medidas técnicas aptas a manter os seus dados pessoais seguros e protegidos de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer outra forma de tratamento inadequado ou ilícito, sempre à luz das regras aplicáveis de proteção de dados e segurança da informação.</p>
                <p>Nossas medidas de segurança são continuamente monitoradas e revisadas de acordo com os avanços tecnológicos e recursos organizacionais.</p>
              </li>

              <li id='duvidas'>
                <h1>DÚVIDAS?</h1>
                <p>Se tiver qualquer dúvida, você pode entrar em contato com o nosso Encarregado pelo tratamento de dados pessoais, Ronaldo Ricioni, por meio do e-mail <a href="mailto:sustentacao@mobsd.com.br">sustentacao@mobsd.com.br</a>.</p>
              </li>

              <li id='atualizacao'>
                <h1>ATUALIZAÇÃO</h1>
                <p>Pode ser que as informações aqui disponibilizadas sejam atualizadas ou alteradas, para refletir a realidade das nossas operações. Por isso, aconselhamos que você consulte esse Aviso periodicamente.</p>
              </li>
            </ol>
          </section>
        </Body>
      </Container>
    </Box>
  );
}

export default Privacy;
