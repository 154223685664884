import MobEpiApiProvider from "../providers/MobEpiApiProvider";

class AuthApiService extends MobEpiApiProvider {

    async login(email: string, password: string): Promise<any> {
        await this.makeHttpRequest({
            method: "POST",
            url: `/Auth/password`,
            data: {
                email, password
            }
        })
    }

    async refresh(): Promise<any> {

        this.setInterceptorActivated(false);

        await this.makeHttpRequest({
            method: "POST",
            url: `/Auth/refresh`,
            data: {
            }
        })

        this.setInterceptorActivated(true);
    }

    async logout(): Promise<any> {
        await this.makeHttpRequest({
            method: "POST",
            url: `/Auth/logout`,
        })
    }

    interceptors(statusCode: number, data: any, error: any, config: any, instance: any): Function {
        const interceptors = {
            401: async () => {
                let refreshed = false
                try {
                    await this.refresh();
                    refreshed = true;
                } catch (error) {
                    instance.cancel();
                } finally {
                    if(refreshed) {
                        return instance(config)
                    }

                    window.location.href = "/admin/logout";
                    return Promise.reject(error);
                }
            }
        }

        return interceptors[statusCode as keyof typeof interceptors];
    }
}

export default AuthApiService;