import React from 'react';
import "./index.css";
import Footer from "./components/footer";
import Header from "./components/header";
import SearchBar from './components/search-bar'
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="layout-wrapper">
      <Header className="layout-header" />
      <div className="layout-main">
        <SearchBar />
        <Outlet />
      </div>
      <Footer className="layout-footer" />
    </div>
  );
}

export default Layout;
