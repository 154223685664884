import { Box, Button, Grid, Typography, } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


export interface ICard {
    id: String;
    producerName: String,
    type: String,
}

const Card = ({ id, producerName, type }: ICard) => {
    const navigate = useNavigate();

    const seeDetails = () => navigate(`/cas/ca/${id}`)

    return <Box sx={{ bgcolor: 'primary.light', width: 1, height: '25%' }}>

        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={10} sm={8}>
                    <Typography variant="h5">
                        CA {id}
                    </Typography>
                    <Typography variant="subtitle2">
                        {producerName}
                    </Typography>
                    <Typography variant="overline">
                        {type}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2} sm={4}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={seeDetails}
                    >
                        Ver detalhes
                    </Button>
                </Grid>
            </Grid>
        </Box>

    </Box >
}

export default Card;
