import React from 'react';
import { Route } from 'react-router-dom';
import Layout from './layout';
import Clients from './pages/Clients';
import ClientsDetails from './pages/ClientsDetails';
import Logout from './utils/Logout';


const Admin = () => {

    return (<>
        <Route path="" element={<Layout />}>
            <Route path="" element={<Clients />} />

            <Route path="clientes">
                <Route path="" element={<Clients />} />
                <Route path=":id" element={<ClientsDetails />} />
            </Route>

            <Route path="logout" element={<Logout />} />

            <Route path="*" element={<Clients />} />
        </Route>
    </>
    )

}

export default Admin;
