import { createSlice } from '@reduxjs/toolkit';

interface IToken {
    token: string,
    expires: number,
}

interface IAuth {
    accessToken: IToken,
    refreshToken: IToken,
}

interface IInitialState {
    loading: boolean,
    data: IAuth | null,
    error: any,
    logged: boolean
}

const initialState: IInitialState = {
    loading: false,
    data: null,
    error: null,
    logged: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        requestStarted(state) {
            state.loading = true;
        },
        requestSuccess(state) {
            state.loading = false;
            state.error = null;
            state.logged = true;
        },
        requestError(state, action) {
            state.loading = false;
            state.data = null;
            state.error = action.payload;
            state.logged = false;
        },
        requestClear(state) {
            state.loading = false;
            state.data = null;
            state.error = null;
            state.logged = false;
        }
    }
});

export default authSlice.reducer;
export const { requestStarted, requestSuccess, requestError, requestClear } = authSlice.actions;