import React from "react";
import './index.css'
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import {Link} from "react-router-dom";
import LogoVicsa from "@/assets/img/logovicsa.png";

const FooterItem = (props: { title: string, children: any }) => {
  const { title, children } = props;

  return (
    <Box sx={{ display: 'inline-flex' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box width='100%' sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box flexGrow={1} mr={1} height='50%' borderBottom={'0.25px solid white'}></Box>
          <Typography component="span" variant="body2" fontWeight={200}>{title.toUpperCase()}</Typography>
          <Box flexGrow={1} ml={1} height='50%' borderBottom={'0.25px solid white'}></Box>
        </Box>
        <Box width='100%' sx={{ display: 'flex', justifyContent: 'center' }}>
          {children}
        </Box>
      </Container>
    </Box>
  );
}

const Footer = (props: { className?: string }) => {
  const { className } = props;

  return (
    <Box className={`footer ${className ?? ""}`}>
      <Box
        display='flex'
        flexWrap='wrap'
        justifyContent='center'
        p={2}
        color='#ffffff'
        sx={{ backgroundColor: '#2b2b2b' }}
      >
        <FooterItem title={'desenvolvido por'}>
          <Typography component="a" href="https://www.vicsa.com.br" target="_blank">
            <img src={LogoVicsa} alt="company logo" />
          </Typography>
        </FooterItem>

        <FooterItem title={'informações úteis'}>
          <MuiLink component={Link} to='/aviso-de-privacidade' sx={{ textDecoration: 'none' }}>
            <Typography color='white'>Política de Privacidade</Typography>
          </MuiLink>
  </FooterItem>
      </Box>
      <Box sx={{ backgroundColor: '#1f1f1f' }} className="footer-btn">
        <Button
          sx={{
            backgroundColor: '#1f1f1f',
            borderRadius: 0,
            color: '#ffffff',
            m:0,
            width: '100%'
          }}
        >
          Entre em contato conosco
        </Button>
      </Box>
    </Box>
  );
}

export default Footer;
