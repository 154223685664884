import { Box, Container, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CaApiService from '../../../../services/CaApiService';
import Card, { ICard } from '../Card';
import CardSkeleton from '../CardSkeleton';


interface IFiltersCAs {
    producerName?: string;
    sku?: string;
    type?: string;
}

interface ITableCAs {
    filters: IFiltersCAs
    limit?: number;
}

const TableCAs = ({ filters, limit = 10 }: ITableCAs) => {

    const navigate = useNavigate();

    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [cas, setCas] = useState<ICard[]>([]);


    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        const getCAs = async (filters: IFiltersCAs, page: number) => {
            const caApiService = new CaApiService();
            setLoading(true);
    
            try {
                const {cas: _cas, totalPages: _totalPages } = await caApiService.getByFilters(filters, page, limit);
    
                setCas(_cas);
                setTotalPages(_totalPages);
    
                if (_cas.length === 0)
                    navigate('/cas', {
                        replace: true, state: {
                            severity: 'warning',
                            message: 'Não foi possivel encontrar nenhuma CA com essas especificações'
                        }
                    })
    
            } catch (e) {
                return navigate('/cas', {
                    replace: true, state: {
                        severity: 'error',
                        message: 'Ocorreu um erro ao tentrar encontrar o CA'
                    }
                })
            } finally {
                setLoading(false);
            }
    
    
        } 
        getCAs(filters, page);
    }, [filters, page, limit, navigate])

    if (loading) return <>
        {[...Array(4)].map((x, i) =>
            <Box sx={{ my: 2 }}>
                <CardSkeleton key={i} />
            </Box>
        )}
    </>


    if (cas.length === 0) return <></>


    return (<Container style={{ minHeight: '50vh' }}>

        <Box style={{ minHeight: '50vh' }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {cas.map((ca, i) =>
                <Box key={i} sx={{ my: 1 }}>
                    <Card id={ca.id} producerName={ca.producerName} type={ca.type} key={i} />
                </Box>
            )}
            <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                <Pagination count={totalPages} page={page} onChange={handleChange} />
            </Box>

        </Box>

    </Container>);
}

export default TableCAs;
