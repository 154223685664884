import React, { useEffect } from 'react';
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes } from './router/Routes';
import defaultTheme from './styles/themes/default';
import AuthApiService from './services/AuthApiService';
import { useDispatch } from 'react-redux';
import { requestSuccess, requestStarted, requestError } from './store/slices/auth';


const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const restoreLogin = async () => {

      
      const authApiService = new AuthApiService();
      try {
        dispatch(requestStarted())
        await authApiService.refresh()
        dispatch(requestSuccess())
      } catch (error: any) {
        dispatch(requestError(error.message))
      }

    }

    restoreLogin();
  }, [dispatch])

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
