import { Box, Card, CardContent, Container, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import FormLogin from './components/FormLogin';
import { RootState } from "@/store";
import { Navigate } from 'react-router-dom';

const Login = () => {

    const auth = useSelector<RootState>(state => state.auth) as RootState["auth"];

    if (auth.logged) return <Navigate to="/admin" replace />;

    return (
        <Container>
            <Box sx=
                {{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    py: { xs: 5, md: 8 },
                    px: { xs: 0, md: 8 }
                }}
            >
                <Card style={{ maxWidth: '70vh' }}>
                    {auth.loading && <LinearProgress />}
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            LOGIN
                        </Typography>
                        <FormLogin />
                    </CardContent>

                </Card>
            </Box>
        </Container>
    );
}

export default Login;
