import { Box, Container, Grid, LinearProgress, Link, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FindCA from './components/FindCA';
import Section from './components/Section';
import { useNavigate } from "react-router-dom";
import CaApiService from '../../services/CaApiService';
import downloadCa from '@/assets/img/btn-ca-mte-pdf.png';
import CaState from '@/enums/CaState';

const DetailsCA = () => {
    let { id } = useParams();

    const blobLink = `${process.env.REACT_APP_MOB_EPI_BLOB}${id}.pdf`
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [ca, setCa] = useState<any>(null);

    const colors = {
        [CaState.OVERDUE]: "red",
        [CaState.VALID]: "green"
    }

    const getDueVerb = (dueDate: Date): string => {
        return dueDate.getTime() <= (new Date()).getTime()
            ? `Venceu`
            : `Vence`
    }

    useEffect(() => {
        const getCaById = async (id: number) => {
            const caApiService = new CaApiService();
            setLoading(true);

            try {
                const _ca = await caApiService.getById(id);
                setCa(_ca)

                if (!_ca)
                    navigate('/cas', {
                        replace: true, state: {
                            severity: 'warning',
                            message: 'Não foi possivel encontrar nenhuma CA com essas especificações'
                        }
                    })

            } catch (error) {
                return navigate('/cas', {
                    replace: true, state: {
                        severity: 'error',
                        message: 'Ocorreu um erro ao tentrar encontrar o CA'
                    }
                })
            } finally {
                setLoading(false);
            }

        }
        getCaById(Number.parseInt(id as string));
    }, [id, navigate])


    if (loading) return (<>
        <LinearProgress />
    </>)

    return (<Container>
        <Box mt={5} mb={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} sm={8}>
                    <Box sx={{ mb: 2 }}>
                        <Typography color="text.primary" variant="h4">
                            CA {ca?.id}
                        </Typography>
                        <Typography color="text.disabled" variant="overline">
                            {ca?.type}
                        </Typography>
                        <Typography color="text.primary" style={{ fontWeight: 600 }} variant="subtitle2">
                            {ca?.producerName}
                        </Typography>
                        <Box>
                            <Typography style={{color: colors[String(ca?.status) as keyof typeof colors], fontSize: "1.5rem"}} variant="body1">
                                • {ca?.status}
                            </Typography>
                        </Box>

                        <Typography color="text.disabled" variant="overline">
                            {ca?.dueDate &&
                                `${getDueVerb(ca.dueDate)} em ${ca.dueDate.toLocaleDateString('pt-br')}`
                            }
                        </Typography>
                    </Box>

                    <Box sx={{ borderTop: 0.1 }}>
                        {
                            ca?.sections.map((section: any, i: number) => <Section key={i} title={section.title} text={section.text} />)
                        }
                    </Box>


                </Grid>
                <Grid item xs={12} md={4} sm={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}>
                        <FindCA />
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            <Link align="center" href={blobLink} underline="none">
                                <Box
                                    component="img"
                                    sx={{
                                        width: '50%',
                                    }}
                                    alt="Download official document"
                                    src={downloadCa}
                                ></Box>
                            </Link>
                        </Box>
                    </Box>

                </Grid>
            </Grid>
        </Box>
    </Container >);
}

export default DetailsCA;
