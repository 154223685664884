import React, { useState } from 'react';
import "./index.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { useNavigate } from 'react-router-dom';


const SearchBar = () => {

  const navigate = useNavigate();
  const [ca, setCa] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCa(event.target.value);
  };

  return (
    <Container maxWidth={false} sx={{ display: 'flex', background: 'linear-gradient(to bottom, #914600 0%,#ff6217 62%)' }}>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: 'flex', alignSelf: 'end', px: 2, py: 1, gap: '0.5rem' }}>
        <TextField
          className='search-bar'
          variant="outlined"
          value={ca}
          onChange={handleChange}
          size="small"
          placeholder="BUSQUE AQUI O SEU CA"
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(`cas/ca/${ca}`)}
        >
          Buscar
        </Button>
      </Box>
    </Container>
  )
}

export default SearchBar;