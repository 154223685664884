import { useSelector } from "react-redux";
import { Navigate, Outlet, } from "react-router-dom";
import { RootState } from "@/store";

const AdminWrapper = () => {
    const auth = useSelector<RootState>(state => state.auth) as RootState["auth"];

    const authenticated = auth.logged;

    return authenticated ? <Outlet /> : <Navigate to="/login" replace />;
}

export default AdminWrapper;