import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import ClientsNewModal from "./components/ClientsNewModal";
import ClientsTable from "./components/ClientsTable";

const Accounts = () => {

    const [clientsNewModalOpen, setClientsNewModalOpen] = useState<boolean>(false);

    return (<Box sx={{ height: '50vh' }}>
        <Box
            sx={{
                p: 1, bgcolor: "primary.main", display: 'flex', alignItems: 'center', justifyContent: 'space-between;',
            }}>
            <Typography variant="h6" color="white">
                Clientes
            </Typography>
            <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => setClientsNewModalOpen(true)}
                style={{ color: 'white' }}
            >Adicionar Cliente</Button>
        </Box>
        <Box>
            <ClientsTable />
        </Box>
        <Box>
            <ClientsNewModal open={clientsNewModalOpen} setOpen={setClientsNewModalOpen} />
        </Box>
    </Box>)
}

export default Accounts;