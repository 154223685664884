import { Box, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import useQuery from '../../hooks/useQuery';
import TableCAs from './components/TableCAs';



const CAs = () => {

    const query = useQuery();

    return (<Container>
        <Box sx={{ my: 5 }}>
            <Typography variant='h5'>
                RESULTADO DA BUSCA
            </Typography>

        </Box>

        <Divider light />

        <TableCAs filters={{
            producerName: query.get('producerName') as string,
            sku: query.get('sku') as string,
            type: query.get('type') as string,
        }} />


    </Container>);
}

export default CAs;
