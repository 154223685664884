import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { useNavigate, createSearchParams } from 'react-router-dom';

interface IFormSearchInput {
    ca: string;
    producerName: string,
    sku: string,
    type: string,
}

const FormSearchCA = () => {
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm<IFormSearchInput>();

    const onSubmit: SubmitHandler<IFormSearchInput> = ({ ca, producerName, sku, type }) => {
        if (ca) {
            return navigate(`/cas/ca/${ca}`)
        }

        return navigate({
            pathname: "/cas/search",
            search: createSearchParams({
                producerName,
                sku,
                type
            }).toString()
        });
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2}>
            <Grid xs={12} item>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Digite o número do CA desejado"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    {...register("ca")}
                />
            </Grid>
            <Grid xs={12} item>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Digite o nome do fabricante"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    {...register("producerName")}
                />
            </Grid>
            <Grid xs={12} item>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Digite o SKU/Referência do Equipamento"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    {...register("sku")}
                />
            </Grid>
            <Grid xs={12} item>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Digite o Tipo do EPI"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    {...register("type")}
                />
            </Grid>
            <Grid xs={12} item mt={4}>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    type="submit"

                >
                    Buscar
                </Button>
            </Grid>
        </Grid>
    </form>

}

export default FormSearchCA;
