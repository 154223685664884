//import usePagination from '@mui/material/usePagination/usePagination';
import './style.css'
import { Box, Typography } from '@mui/material';
import React from 'react';


interface ISection {
    title: String;
    text: String;
}

const Section = ({ title, text }: ISection) => {

    return (<Box className="section" sx={{ borderBottom: 0.1, py: 2 }}>
        <Typography color="secondary" variant="h6" gutterBottom component="div">
            {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
            {text}
        </Typography>
    </Box>);
}

export default Section;
