import { Axios as AxiosType, AxiosError } from "axios";
import { HttpProvider } from "./HttpProvider";

let alreadySetInterceptor = false;

class HttpInterceptorsProvider extends HttpProvider {

    private interceptorsActivated: boolean;

    constructor(instance: AxiosType) {
        super(instance);
        this.setResponseInterceptors(instance)
        this.interceptorsActivated = true;
    }

    private setResponseInterceptors(instance: AxiosType) {

        if (alreadySetInterceptor) return

        instance.interceptors.response
            .use(
                response => response,
                async (error: AxiosError) => {

                    if(!this.interceptorsActivated) return Promise.reject(error);

                    const { config } = error
                    const { status, data } = error.response!;

                    try {
                        return await (this.interceptors(status, data, error, config, this.instance))();
                    } catch (e) {
                        return Promise.reject(error);
                    }
                }
            )

        alreadySetInterceptor = true;
    }

    interceptors(statusCode: number, data: any, error: any, config: any, instance: any): Function {
        return async () => Promise.reject(error);
    }

    setInterceptorActivated(status: boolean) {
        this.interceptorsActivated = status;
    }
}

export default HttpInterceptorsProvider;