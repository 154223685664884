import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import AuthApiService from '@/services/AuthApiService';
import { requestStarted, requestSuccess, requestError } from '@/store/slices/auth';


interface IFormLogin {
    email: string;
    password: string,
}

const FormLogin = () => {
    const dispatch = useDispatch();
    const authApiService = new AuthApiService();
    const auth = useSelector<RootState>(state => state.auth) as RootState["auth"];
    const { register, handleSubmit } = useForm<IFormLogin>();

    const onSubmit: SubmitHandler<IFormLogin> = async ({ email, password }) => {
        try {
            dispatch(requestStarted())
            await authApiService.login(email, password);
            dispatch(requestSuccess())
        } catch (error: any) {
            dispatch(requestError(error?.message))
        }
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2}>
            <Grid xs={12} item>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Email"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    {...register("email")}
                />
            </Grid>
            <Grid xs={12} item>
                <TextField
                    variant="outlined"
                    size="small"
                    type="password"
                    placeholder="Senha"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    {...register("password")}
                />
            </Grid>
            <Grid xs={12} item mt={4}>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    type="submit"
                    disabled={auth.loading}
                >
                    Login
                </Button>
            </Grid>
        </Grid>
    </form>

}

export default FormLogin;
