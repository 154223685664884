import MobEpiApiProvider from "../providers/MobEpiApiProvider";
import SectionCa from "@/enums/SectionCa";

class CaApiService extends MobEpiApiProvider {

    async getById(id: number): Promise<any> {
        let result: any;
        try {
            result = await this.makeHttpRequest({
                method: "GET",
                url: `/Ca/${id}`
            })
        } catch (error: any) {
            
            if(error?.response?.status === 404) {
                return null
            }

            throw error;
        }


        const sectionsAlias = {
            dsequipamento: SectionCa.DESCRIPTION,
            dsaprovadoparalaudo: SectionCa.APPROVED_FOR,
            dsreferencia: SectionCa.PRODUCT_REFERENCE,
            norazaosocial: SectionCa.PRODUCER,
            dslocalmarcacaoca: SectionCa.CA_MARKING,
            nonorma: SectionCa.TECHNICAL_STANDARDS,
            obanaliselaudo: SectionCa.OBSERVATION
        }

        const sections = Object.entries(sectionsAlias)
            .map(sectionAlias => {
                return {
                    title: sectionAlias[1],
                    text: result[sectionAlias[0]]
                }
            })
            .filter(section => section.text !== "")

        const ca = {
            id: result.nrCa,
            type: result.noequipamento,
            producerName: result.norazaosocial,
            status: result.idsituacao,
            dueDate: new Date(result.dtWessyValidade),
            sections
        }

        return ca;
    }

    async getByFilters(filter: any, page: number, limit: number): Promise<any> {

        const results = await this.makeHttpRequest({
            method: "GET",
            url: `/Ca/`,
            params: {
                Page: page,
                PageSize: limit,
                Sku: filter.sku,
                Fabricante: filter.producerName,
                TipoEpi: filter.type
            }
        })

        const cas = results.data.map((ca:any) => {
            return {
                id: ca.nrCa,
                producerName: ca.norazaosocial,
                type: ca.noequipamento
            }
        })

        return {
            cas,
            totalPages: results.totalPages
        }
    }
}

export default CaApiService;