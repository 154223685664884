import React from 'react';
import { Routes as RoutesDom, Route, } from 'react-router-dom';
import CAs from '../pages/CAs';
import DetailsCA from '../pages/DetailsCA';
import SearchCA from '../pages/SearchCA';
import Privacy from '../pages/Privacy';
import Admin from '@/pages/Admin';
import AdminWrapper from '@/pages/Admin/utils/AdminWrapper';
import Login from '@/pages/Login';

import Layout from '@/layout';


function Routes() {
    return (
        <RoutesDom>

            <Route path="/" element={<Layout />}>
                <Route path="/" element={<SearchCA />} />
                <Route path="/cas" element={<SearchCA />} />
                <Route path="/cas/search/" element={<CAs />} />
                <Route path="/cas/ca/:id" element={<DetailsCA />} />
                <Route path="/aviso-de-privacidade" element={<Privacy />} />
                <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/admin" element={<AdminWrapper />}>
                {Admin()}
            </Route>

        </RoutesDom>
    );
}

export { Routes };
