import { Alert, AlertTitle, Box, Button, IconButton, Typography } from "@mui/material";
import ApiTable from "./components/ApiTable";
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ApiClientApiService from "@/services/ApiClientApiService";
import { useState } from "react";
import DownloadFile from "@/utils/DownloadFile";

interface IApiTab {
    clientId: string;
}

const ApiTab = ({ clientId }: IApiTab) => {
    const apiClientApiService = new ApiClientApiService()

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [newApi, setNewApi] = useState<any>(null);

    const createApiAccess = async () => {
        try {
            const api = await apiClientApiService.create(Number.parseInt(clientId))
            console.log(api);
            setNewApi(api);
            setShowDialog(true);
            
        } catch (error) {
            console.log(error)
        }
    }

    const downloadApi = async () => {
        DownloadFile.export(JSON.stringify({...newApi, id: undefined}), "credentials.json", "text/json");
    }

    return <Box>
        <Box>
        <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={createApiAccess}
                style={{ color: 'white' }}
            >
                Criar novo acesso de API
            </Button>
        </Box>
        {(showDialog && newApi) && <Box mt={2}>
        <Alert severity="success"
              action={
                  <Box sx={{display: "flex", flexDirection: "column", gap: ".4rem"}}>
                    <IconButton onClick={() => setShowDialog(false)}>
                        <CloseIcon style={{fontSize: "1.5rem"}}/>
                    </IconButton>
                    <IconButton onClick={downloadApi}>
                        <DownloadIcon style={{fontSize: "1.5rem", alignSelf: "end"}}/>
                    </IconButton>
                  </Box>
              }>
            <AlertTitle>API Key criado com sucesso!</AlertTitle>
            <Box sx={{ wordBreak: "break-word"}}>
                    <Typography variant="body1" color="#1E4620">
                        Code: {newApi?.code}
                    </Typography>
                    <Typography variant="body1" color="#1E4620">
                        Secret: {newApi?.secret}
                    </Typography>
            </Box>
            <Box mt={2}>
                <Typography variant="body1" color="#1E4620">
                    Guarda essas informacoes em um lugar seguro, não sera possivel recupera-las posteriormente
                </Typography>
            </Box>

        </Alert>
        </Box>}
        <ApiTable clientId={clientId}/>
    </Box>
}

export default ApiTab