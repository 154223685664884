import { Box, Grid, Skeleton, } from '@mui/material';
import React from 'react';


const CardSkeleton = () => {

    return <Box sx={{ bgcolor: 'primary.light', width: 1, height: '25%' }}>

        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={10} sm={8}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs={12} md={2} sm={4}>
                    <Skeleton variant="rectangular" width={100} height={50} />
                </Grid>
            </Grid>
        </Box>

    </Box >
}

export default CardSkeleton;
