import { createTheme } from "@mui/material";


const defaultTheme = createTheme({
    typography: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            light: '#ebebeb',
            main: '#424242',
            dark: '#1f1f1f',
            contrastText: '#fff'
        },

        text: {
            primary: '#424242',
            disabled: '#b1b1b1'
        },

        secondary: {
            main: '#fe6217',
            contrastText: '#000',
        },
    },
});

export default defaultTheme;