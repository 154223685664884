import { combineReducers, configureStore, } from '@reduxjs/toolkit'

import auth from './slices/auth'

const reducer = combineReducers({ auth });
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export default store;

export type RootState = ReturnType<typeof store.getState>