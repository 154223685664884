import { IconButton, Toolbar, Typography } from '@mui/material';
import AppBarMui from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';

interface IAppBar {
    drawerWidth: number;
    handleDrawerToggle: any
}

const AppBar = ({ drawerWidth, handleDrawerToggle }: IAppBar) => {
    return <AppBarMui
        position="fixed"
        sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            bgcolor: "secondary.main"
        }}
    >
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
                MOB EPI ADMISTRATIVO
            </Typography>
        </Toolbar>
    </AppBarMui>
}

export default AppBar