import ApiClientApiService from "@/services/ApiClientApiService";
import { Box, Pagination } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import ApiCard, {IApiCard} from "../ApiCard";

interface IApiTable {
    clientId: string;
}

const ApiTable = ({clientId}: IApiTable) => {
    
    const limit = 10;
    const [page, setPage] = useState<number>(1);
    const [apis, setApis] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() =>  {
        const getApis = async () => {
            const apiClientApiService = new ApiClientApiService();
            try {
                setLoading(true);
                const {apis: _apis, totalPages: _totalPages} = await apiClientApiService.get(Number.parseInt(clientId), page, limit);
                setApis(_apis)
                setTotalPages(_totalPages);
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        }

        getApis();
    }, [page, clientId])

    if (loading) return <></>

    return (
    <Suspense>
        <Box style={{ minHeight: '50vh' }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {apis?.map(({id, code, createdAt}: IApiCard, i: number) => <Box key={i} mt={2}>
                <ApiCard  key={i} id={id} code={code} createdAt={createdAt} setApis={setApis}/>
            </Box>)
            }

            <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                <Pagination count={totalPages} page={page} onChange={handleChange} />
            </Box>

        </Box>
    </Suspense>
    )
}

export default ApiTable;