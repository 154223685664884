
import AuthApiService from "./AuthApiService";

class ApiClientApiService extends AuthApiService {
    async get(userId: number, page: number, limit: number) {
        const result = await this.makeHttpRequest({
            method: "GET",
            url: `/ApiClient/`,
            params: {
                userId,
                Page: page,
                PageSize: limit
            }
        })

        return {
            apis: result.data,
            totalPages: result.totalPages
        }
    }

    async create(userId: number) {
        const result = await this.makeHttpRequest({
            method: "POST",
            url: `/ApiClient/`,
            data: {
                userId
            }
        })

        return result;
    }

    async delete(id: number) {
        const result = await this.makeHttpRequest({
            method: "DELETE",
            url: `/ApiClient/${id}`,
        })

        return result;
    }
}

export default ApiClientApiService;