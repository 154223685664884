import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, IconButton, Switch } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate } from 'react-router-dom';
import UserApiService from '@/services/UserApiService';


const ClientsTable = () => {

    const limit = 10;
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState<any>(null)

    const openClientDetails = (clientId: number) => {
        navigate(`/admin/clientes/${clientId}`)
    }

    const updateStatus = async (clientId: number, newStatus: boolean) => {
        const userApiService = new UserApiService();

        try {
            await userApiService.update(clientId, {active: newStatus})
        } catch (error) {
            
        }
    }

    const toggleClient = async (event: React.ChangeEvent<HTMLInputElement>, clientId: number) => {
        event.stopPropagation();

        let newStatus: boolean = true;
        setTableData((tableData: any) => ({
            ...tableData,
            clients: tableData.clients.map((client: any) => {
                if (client.id === clientId) {
                    newStatus = !client.active
                    return { ...client, active: newStatus }
                }
                return client
            })
        }));

        updateStatus(clientId, newStatus);

    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Nome', width: 300 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'responsible', headerName: 'Responsavel', width: 200 },
    ];

    const actionsColumns = [
        {
            field: 'details',
            headerName: '#',
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params: GridValueGetterParams) => {
                return <Box>
                    <IconButton onClick={() => openClientDetails(params.row.id)}>
                        <SettingsOutlinedIcon />
                    </IconButton>
                </Box>
            }
        },
        {
            field: 'toggle',
            headerName: '#',
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params: GridValueGetterParams) => {
                return <Switch checked={params.row.active} onChange={(e) => toggleClient(e, params.row.id)} color="secondary" />

            }
        },
    ]


    React.useEffect(() => {
        const getClients = async () => {
            const userApiService = new UserApiService();
            try {
                setLoading(true);
                const users = await userApiService.get(page+1, limit)
                setTableData({
                    clients: users.data,
                    total: users.total
                })
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

        getClients();
    }, [page]);

    if (!tableData) return <></>

    return (
        <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
                sx={{
                    borderTop: 'none',
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '0'
                }}
                rows={tableData?.clients}
                rowCount={tableData?.total}
                loading={loading}
                rowsPerPageOptions={[limit]}
                pagination
                page={page}
                pageSize={limit}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                columns={columns.concat(actionsColumns)}
                disableSelectionOnClick
            />
        </div>
    );
}

export default ClientsTable;