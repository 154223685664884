//import usePagination from '@mui/material/usePagination/usePagination';
import { SearchOutlined } from '@mui/icons-material';
import { Box, Button, InputAdornment, TextField, } from '@mui/material';
import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';


interface IFormInput {
    id: String;
}

const FindCA = () => {
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = ({ id }) => navigate(`/cas/ca/${id}`)

    return (<Box sx={{ display: 'flex', }}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
                variant="standard"
                size="small"
                placeholder="Digite o CA Desejado"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                }}
                {...register("id")}

            />
            <Button
                variant="contained"
                size="small"
                type="submit"
            >
                Buscar
            </Button>
        </form>
    </Box>
    );
}

export default FindCA;
