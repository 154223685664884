import { Alert, Box, Card, CardContent, Container, Snackbar, Typography, AlertColor } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FormSearchCA from './components/FormSearchCA';
import './style.css'


type LocationState = {
    severity: AlertColor,
    message: string,
}

const SearchCA = () => {

    const state = (useLocation()).state as LocationState;

    const [showAlert, setShowAlert] = useState<boolean>(false);

    useEffect(() => {
        if (state) setShowAlert(true);

        window.history.replaceState({}, document.title)
    }, [state])


    return (<Box sx={{ height: 1 }} className="search-ca">
        <Box sx={{
            bgcolor: 'primary.light',
            p: { xs: 2, md: 2 },
            display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}>
            <Typography gutterBottom variant="h5">
            Consulte Certificados de Aprovação (CAs) e baixe o documento oficial do Ministério do Trabalho e Emprego
            </Typography>
        </Box>
        <Container>
            <Box sx=
                {{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'column',
                    py: { xs: 5, md: 8 },
                    px: { xs: 0, md: 8 }
                }}
            >
                <Card style={{ maxWidth: '70vh' }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            BUSCA DE CAS
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            Busque por pelo menos um dos campos
                        </Typography>
                        <FormSearchCA />
                        {state &&
                            <Snackbar
                                open={showAlert}
                                autoHideDuration={3000}
                                onClose={() => setShowAlert(false)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert
                                    onClose={() => setShowAlert(false)}
                                    severity={state.severity}
                                    sx={{ width: '100%' }}>
                                    {state.message}
                                </Alert>
                            </Snackbar>
                        }
                    </CardContent>

                </Card>
            </Box>
        </Container>
    </Box>);
}

export default SearchCA;
