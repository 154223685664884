import axios from "axios";
import HttpInterceptorsProvider from "./HttpInterceptorsProvider";

const instance = axios.create({
    baseURL: process.env.REACT_APP_MOB_EPI_API_BASE_URL,
    withCredentials: true,
});


class MobEpiApiProvider extends HttpInterceptorsProvider {

    constructor() {
        super(instance);
    }
}

export default MobEpiApiProvider;