import Axios, {
    Axios as AxiosType,
    AxiosRequestConfig,
} from "axios";

const TIMEOUT = 300000;

export interface HttpConfig extends AxiosRequestConfig<any> { }

export class HttpProvider {
    protected instance: AxiosType;

    constructor(axiosInstance: AxiosType) {
        if (!axiosInstance) {
            throw new Error("Setup do axios não fornecido");
        }

        this.instance = axiosInstance;
    }

    setHeader(name: string, value: string) {
        this.instance.defaults.headers.common[name] = value;
    }

    removeHeader(name: string) {
        delete this.instance.defaults.headers.common[name];
    }

    async makeHttpRequest(config: HttpConfig) {
        try {
            const source = await Axios.CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, TIMEOUT + 10000);

            config.timeout = config.timeout || TIMEOUT;
            config.cancelToken = source.token;

            const httpResponse = await this.instance.request(config);

            return config.responseType === "blob" ? httpResponse : httpResponse.data;
        } catch (e: any) {
            if (!e.response) {
                throw e;
            }

            return this.handleRequestError(e, config);
        }
    }

    handleRequestError(e: any, config: HttpConfig) {
        if (process.env.REACT_APP_DEBUG === "true") {
            const reponsePayload =
                typeof e.response.data === "string"
                    ? e.response.data
                    : JSON.stringify(e.response.data);

            console.error(`
          Error: ${e.message},
          StatusCode: ${e.response.status},
          Request Config: ${JSON.stringify(config)},
          Response Payload: ${reponsePayload}
        `);
        }

        if (typeof e?.response?.data !== "string" && e?.response?.data?.errors) {
            const temp = e.response.data.errors;
            e.response.data = temp[Object.keys(temp)[0]][0];
        }

        throw e;
    }
}
